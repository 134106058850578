
import { Page, ProfilePic, Company, Tagline, SocialIcons, Footer, ButtonLinks, IconLink, IconWrapper } from "../Styles/Page.style";
import { FaEnvelope, FaInstagramSquare, FaWhatsapp, FaPhone, FaHammer } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import {FiGlobe} from "react-icons/fi";
import React, { useState, useRef } from 'react';



export default function PageContainer() {
    const [copySuccess, setCopySuccess] = useState('Copy');
    const textAreaRef = useRef(null);


    const CopyToClipboard = async () => {
        const text = textAreaRef.current.textContent;  // Changed to textContent
        try {
            await navigator.clipboard.writeText(text);
            setCopySuccess('Copied');
        } catch (err) {
            console.error('Failed to copy: ', err);
            setCopySuccess('Failed to copy');
        }
    };


    return (
        <>
            <Page>
                <ProfilePic src="https://pps.whatsapp.net/v/t61.24694-24/316999645_366954979193857_4577853100294181811_n.jpg?ccb=11-4&oh=01_ASCzfWSl5bE4PhdlBBgm510qxRmj3SwfwQGRdGeHKEDYBQ&oe=660734D7&_nc_sid=e6ed6c&_nc_cat=103" alt="Weard Company" />
                <Company>Weard Company</Company>
                <Tagline>Too hot to handle. Too cool to hold</Tagline>

                <SocialIcons>
                    <IconLink >
                        <FaPhone /> <span ref={textAreaRef}>08028974347</span>
                        </IconLink>
<IconLink>                    <div>
                        <button onClick={CopyToClipboard}>{copySuccess}</button></div>
                    </IconLink>
                </SocialIcons>
                <IconWrapper>
                    <ButtonLinks href="https://wa.me/message/ELTH2ZIT4UXGM1"
                    >  <IconLink> <FaWhatsapp />  WhatsApp</IconLink></ButtonLinks>
                    <ButtonLinks href="https://x.com/theweardcompany?s=21"><IconLink href="https://x.com/theweardcompany?s=21">
                        <FaTwitter /> Twitter
                    </IconLink></ButtonLinks>
                    <ButtonLinks href="mailto:weardcompany@gmail.com"><IconLink >
                        <FaEnvelope /> Email
                    </IconLink></ButtonLinks>
                    <ButtonLinks href="https://www.instagram.com/weardcompany?igsh=dWxnN293cWJ3N2gy&utm_source=qr"><IconLink href="https://www.instagram.com/weardcompany?igsh=dWxnN293cWJ3N2gy&utm_source=qr">
                        <FaInstagramSquare /> Instagram
                    </IconLink></ButtonLinks>
                    <ButtonLinks href="https://weardcompany.my.canva.site">
                        <IconLink><FiGlobe /> 
                        Visit Store</IconLink>
                    </ButtonLinks>

                </IconWrapper>
                <Footer><FaHammer /> 
                   <a style={{
                        textDecoration: "none",
                        color: "skyblue",
                        fontWeight: "500",
                 
                    }} href="https://github.com/michuyim"> Michuyim</a></Footer>
            </Page >
        </>
    );
};