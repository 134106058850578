import PageContainer from "./Components/LandingPage";
import { GlobalStyles } from "./Styles/GlobalStyles";

function App() {
  return (
    <>
      <GlobalStyles />
      <PageContainer />
    </>
  );
}

export default App;
